import { version } from './version';
export const environment = {
  Version:version.Version,
  environment: 'Stage',
  envName :'stage',
  production: true,
  apiUrl: 'https://stage.api.merchant.grubbrr.com/api/kiosk/legacy/',
  imageBaseUrl: 'http://localhost:4200/static/',
  imageBaseUrlRemote: 'https://ik.imagekit.io',
  offlinePort: '8080',
  cloverNodeJsUrl : 'https://app-smb-stage-eastus-clover-node-api.azurewebsites.net',
  baudRate: 115200,
  usConnectKey: 'tHoPOMCm23teMXYNIl4CITMQItLbU6Go',
  NodeUrl: "http://localhost:4200/",
  IsShowLog: false,
  showLanguagePicker: true
};

